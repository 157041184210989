import axios from 'axios';

const numOfPages= 4;
const addWayPoints = (elementIdHandlerArr) => {
  elementIdHandlerArr.forEach((elementIdHandlerMap) => {
    if(document.getElementById(elementIdHandlerMap.elementId)) {
      new Waypoint({
        element: document.getElementById(elementIdHandlerMap.elementId),
        handler: elementIdHandlerMap.handler,
        offset: 10,
      });
    }
  })
};

const getPageHandler = (pageNum) => () => {
  // Adjust the summary view class
  const summarySectionElement =
    document.querySelector('.summary-section');

  const summaryClassArr = [...Array(numOfPages).keys()]
    .map((num) => `summary-page-${num + 1}`);

  summarySectionElement.classList.remove(...summaryClassArr);
  summarySectionElement.classList.add(`summary-page-${pageNum}`);

  document.querySelector('.reading-progress-wrapper .active').classList.remove('active');
  document.querySelector(`.reading-progress-wrapper #page-${pageNum}-progress`).classList.add('active');


  const fullWidthButtons = document.querySelector('.full-width-buttons');
  const buttonsClassArr = [...Array(numOfPages).keys()]
    .map((num) => `buttons-${num + 1}`);

  fullWidthButtons.classList.remove(...buttonsClassArr);
  fullWidthButtons.classList.add(`buttons-${pageNum}`);
};

const main = () => {
  const elementIdHandlerArr = [...Array(numOfPages).keys()].map((num) => ({
    elementId: num === 0
      ? 'page-' + (num + 1)
      : 'page-' + (num + 1) + '-track',
    handler: getPageHandler((num + 1))
  }));

  addWayPoints(elementIdHandlerArr);
};

main();

(function() {
  const form = document.querySelector('form.donate');
  const buttons = form.querySelectorAll('button:not([type="submit"])');
  const numInput = form.querySelector('input[type="number"]');
  const makeDonation = document.querySelector('.make-donation-button');
  const backArrow = document.querySelector('.back-arrow');
  const closeButton = document.querySelectorAll('.close-button');
  const donatePopupButton = document.querySelectorAll('.donate-popup-button');
  const goToNewsletter = document.querySelectorAll("#go-to-newsletter");
  const bars = document.querySelector('.bars');
  const closeMenuButton = document.querySelector('.close-menu-button');
  
  function resetButtons() {
    buttons.forEach((button) => { button.classList.remove('selected') })
  }

  function submitCheckout(amount) {
    axios.get('/.netlify/functions/checkout', { params: { amount: parseInt(amount * 100) } })
      .then((resp) => {
        window.location.href = resp.data.url;
      });
  }

  buttons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      resetButtons();
      e.target.classList.add('is-loading');
      numInput.value = "";
      submitCheckout(e.target.innerText.replace('$', ''));
    });
  });  

  numInput.addEventListener('change', (e) => {
    resetButtons();
  });
  
  form.addEventListener('submit', (e) => {
    e.preventDefault();

    let amount;

    if (numInput.value) {
      amount = numInput.value;
    }
    else {
      return;
    }

    form.querySelector('button[type="submit"]').classList.add('is-loading');

    submitCheckout(amount);
  });

  makeDonation.addEventListener('click', (e) => {
    document.getElementsByClassName('donation-page')[0].style.display = 'block';
    document.getElementsByClassName('newsletter')[0].style.display = 'none';
  });

  backArrow.addEventListener('click', (e) => {
    document.getElementsByClassName('donation-page')[0].style.display = 'none';
    document.getElementsByClassName('newsletter')[0].style.display = 'block';
  });

  closeButton.forEach(c => {
    c.addEventListener('click', (e) => {
      document.getElementsByClassName('donation-page')[0].classList.remove('popup');
      document.getElementsByClassName('newsletter')[0].style.display = 'none';
      document.getElementsByClassName('donation-page')[0].style.display = 'none';
      document.getElementsByClassName('pages-section')[0].style.width = '100%';
      
      let fullPages = document.getElementsByClassName('page');
      
      Array.prototype.forEach.call(fullPages, function(p) {
        p.classList.add('narrow');
      });
      
      document.getElementsByClassName('reading-progress-indicator')[0].style.top = '50px';
      
      let pages = document.getElementsByClassName('page-progress');
      Array.prototype.forEach.call(pages, function(p) {
        p.style.top = 'unset';
      });
      
      if(window.screen.width > 767) {
        document.getElementsByClassName('full-width-buttons')[0].style.display = 'flex';
      }

      var styleElem = document.head.appendChild(document.createElement("style"));

      styleElem.innerHTML = ".reading-progress-indicator:before {top: 80px !important;}";
    });
  });

  donatePopupButton.forEach(d => {
    d.addEventListener('click', (e) => {
      document.getElementsByClassName('donation-page')[0].classList.add('popup');
      closeMobileMenu();
    });
  });

  goToNewsletter.forEach(n => {
    n.addEventListener('click', (e) => {
      window.scroll({
        top: 3000,
        behavior: 'smooth'
      });
      closeMobileMenu();
    });
  });

  bars.addEventListener('click', (e) => {
    openMobileMenu();
  });

  closeMenuButton.addEventListener('click', (e) => {
    closeMobileMenu();
  });

  function openMobileMenu() {
    document.getElementsByClassName('bars')[0].style.display = 'none';
    document.getElementsByClassName('close-menu-button')[0].style.display = 'block';
    document.getElementsByClassName('page')[0].classList.add('dimmed');
    document.getElementById('mobileMenuButtons').style.display = 'flex';
    document.getElementsByClassName('reading-progress-wrapper')[0].style.display = 'none';
    document.getElementsByClassName('reading-progress-indicator')[0].classList.add('no-before');
  }

  function closeMobileMenu() {
    document.getElementsByClassName('close-menu-button')[0].style.display = 'none';
    document.getElementsByClassName('bars')[0].style.display = 'block';
    document.getElementsByClassName('page')[0].classList.remove('dimmed');
    document.getElementById('mobileMenuButtons').style.display = 'none';
    document.getElementsByClassName('reading-progress-wrapper')[0].style.display = 'block';
    document.getElementsByClassName('reading-progress-indicator')[0].classList.remove('no-before');
  }

  /*const emailForm = document.querySelector('form.petition-form-form');
  emailForm.addEventListener('submit', (e) => {
    e.preventDefault();

    let email = emailForm.querySelector('input[name="email"]').value;
    let postalCode = emailForm.querySelector('input[name="postal_code"]').value;
    let signup = emailForm.querySelector('input[name="signup"]');

    if ( email && postalCode ) {
      emailForm.querySelector('button[type="submit"]').classList.add('is-loading');
      axios.post('/.netlify/functions/email', null, { params: { email: email, postalCode: postalCode, signUp: signup.checked ? "true" : "false" } })
        .then((resp) => {
          emailForm.querySelector('button[type="submit"]').classList.remove('is-loading');
          emailForm.querySelector('div.notification').classList.remove('is-hidden');
          emailForm.querySelector('input[name="email"]').value = "";
          emailForm.querySelector('input[name="postal_code"]').value = "";
          signup.checked = false;
        });
    }
  });

  emailForm.querySelector('div.notification button.delete').addEventListener('click', (e) => {
    emailForm.querySelector('div.notification').classList.add('is-hidden');
  });*/

  const subscribeForm = document.querySelector('form.subscribe-form-form');
  subscribeForm.addEventListener('submit', (e) => {
    e.preventDefault();

    let email = subscribeForm.querySelector('input[name="email"]').value;
    
    if ( email ) {
      subscribeForm.querySelector('button[type="submit"]').classList.add('is-loading');
      axios.post('/.netlify/functions/subscribe', null, { params: { email: email } })
        .then((resp) => {
          subscribeForm.querySelector('button[type="submit"]').classList.remove('is-loading');
          subscribeForm.querySelector('div.newsletter-notification').classList.remove('is-hidden');
          subscribeForm.querySelector('input[name="email"]').value = "";
        });
    }
  });

})();

